import { IAction } from "../utils";
import { reducerTypes } from "./types.ts";
import { IDetails } from "../../interfaces/details.ts";
import { IProducts } from "../../interfaces/products.ts";

export interface IProductsReducer {
  details: IDetails[] | [];
  products: IProducts[] | [];
}

export const INITIAL: IProductsReducer = {
  details: [],
  products: [],
};

export const ProductsReducer = (state = INITIAL, { type, payload }: IAction) => {
  switch (type) {
    case reducerTypes.GET_PRODUCTS:
      return { ...state, products: payload };
    case reducerTypes.GET_DETAILS:
      return { ...state, details: payload };
    default:
      return state;
  }
};
