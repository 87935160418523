import * as React from "react";
import { useState } from "react";
import { Box, Stack, Typography, Container, Button, TextField } from "@mui/material";
import { axiosCreateProducts, axiosGetAllProducts } from "../api/products.ts";
import { reducerTypes } from "../store/Products/types.ts";
import { useDispatch } from "react-redux";

function AdminWindow() {
  const dispatch = useDispatch();
  const [newProduct, setNewProduct] = useState({ name: "", price: 0 });

  async function createNewProduct(newProduct) {
    const result = await axiosCreateProducts({ ...newProduct });
    if (result) {
      const products = await axiosGetAllProducts();
      if (products[0]) {
        setNewProduct({ name: "", price: 0 });
        dispatch({
          type: reducerTypes.GET_PRODUCTS,
          payload: result,
        });
      }
    }
  }

  return (
    <Container maxWidth="lg1286" style={{ textAlign: "center", background: "rgba(0, 0, 0, 0.3)" }}>
      <Typography variant="h2">Admin Page</Typography>
      <Typography variant="h4">Добавить продукт</Typography>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <TextField id="outlined-basic" label="Название продукта" variant="outlined" value={newProduct.name} onChange={(e) => setNewProduct((prev) => ({ ...prev, name: e.target.value }))} />
        <TextField id="outlined-basic" type="number" label="Цена" variant="outlined" value={newProduct.price} onChange={(e) => setNewProduct((prev) => ({ ...prev, price: e.target.value }))} />
        <Button style={{ width: "203px", height: "48px" }} variant="contained" onClick={async () => createNewProduct(newProduct)}>
          {" "}
          <Typography variant={"body2"} sx={{ letterSpacing: "2px", fontSize: "16px" }}>
            Создать нахуй
          </Typography>
        </Button>
      </div>
    </Container>
  );
}

export default AdminWindow;
