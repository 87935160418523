import * as React from "react";
import { useState, useEffect } from "react";
import { Typography, Button, TextField } from "@mui/material";
import { reducerTypes } from "../store/Products/types.ts";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store/reduxHooks.ts";
import { axiosCreateDetail, axiosGetAllDetails } from "../api/details.ts";

function Product({ item }) {
  const dispatch = useDispatch();
  const { details } = useAppSelector((store) => store.products);
  const [open, setOpen] = useState(false);
  const [currentDetails, setCurrentDetails] = useState([]);
  const [newDetail, setNewDetail] = useState({ name: "", count: 1, product_id: item.id });

  async function createNewDetail(newDetail) {
    const result = await axiosCreateDetail({ ...newDetail });
    if (result) {
      const details = await axiosGetAllDetails();
      if (details[0]) {
        setNewDetail({ name: "", count: 1, product_id: item.id });
        dispatch({
          type: reducerTypes.GET_DETAILS,
          payload: details,
        });
      }
    }
  }

  useEffect(() => {
    setCurrentDetails(details?.filter((el) => el.product_id === item.id));
    // eslint-disable-next-line
  }, [details]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
      <div style={{ display: "flex", flexDirection: "row", gap: "50px" }} onClick={() => setOpen((prev) => !prev)}>
        <Typography variant="h3">{item.name}</Typography>
        <Typography variant="h3">{item.price}$</Typography>
      </div>
      {open ? (
        <div>
          {currentDetails[0]
            ? currentDetails.map((detail) => (
                <div key={detail.id}>
                  <Typography style={{ textAlign: "start" }} variant="h4">
                    {detail.name} {detail.count} штука
                  </Typography>
                </div>
              ))
            : null}
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <TextField id="outlined-basic" label="Название детали" variant="outlined" value={newDetail.name} onChange={(e) => setNewDetail((prev) => ({ ...prev, name: e.target.value }))} />
            <TextField id="outlined-basic" type="number" label="количество" variant="outlined" value={newDetail.count} onChange={(e) => setNewDetail((prev) => ({ ...prev, count: e.target.value }))} />
            <Button style={{ width: "203px", height: "48px" }} variant="contained" onClick={async () => createNewDetail(newDetail)}>
              {" "}
              <Typography variant={"body2"} sx={{ letterSpacing: "2px", fontSize: "16px" }}>
                Создать нахуй
              </Typography>
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Product;
