export function* rootSaga() {
  //   yield fork(productsWatcherSaga);
  //   yield fork(brandsWatcherSaga);
  //   yield fork(instaMediaWatcherSaga);
  //   yield fork(offersWatcherSaga);
  //   yield fork(modelsWatcherSaga);
  //   yield fork(authWatcherSaga);
  //   yield fork(serviceOrdersWatcherSaga);
  //   yield fork(contactUsWatcherSaga);
  //   yield fork(currencyWatcherSaga);
  //   yield fork(collectionsWatcherSaga);
}
