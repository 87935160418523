import axios from "./axios.ts";

let controllerGetAllProducts: AbortController | null = null;

export const axiosGetAllProducts = async () => {
  try {
    if (controllerGetAllProducts !== null) {
      controllerGetAllProducts.abort();
    }
    controllerGetAllProducts = new AbortController();
    const { data } = await axios.get("api/products/getAll", { signal: controllerGetAllProducts.signal });
    return data.products;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log("Request canceled", e.message);
    } else {
      console.error(e);
    }
  }
};

export const axiosCreateProducts = async ({ name, price }) => {
  try {
    const { data } = await axios.post("api/products/create", { name, price });
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const axiosUpdateProducts = async ({ id, name, price }) => {
  try {
    const { data } = await axios.post("api/products/update", { id, name, price });
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const axiosDeleteProducts = async ({ id }) => {
  try {
    const { data } = await axios.post("api/products/delete", { id });
    return data;
  } catch (e) {
    console.error(e);
  }
};
