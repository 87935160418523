import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store/reduxHooks.ts";
import { reducerTypes } from "../store/Products/types.ts";
import { Box, Stack, Typography, Container, Button } from "@mui/material";
import { HomePageContainer } from "./HomePage.styled.tsx";
import { axiosGetAllDetails } from "../api/details.ts";
import { axiosGetAllProducts } from "../api/products.ts";
import AdminWindow from "../components/AdminWindow.jsx";
import Product from "../components/Product.jsx";

function HomePage() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { products, details } = useAppSelector((store) => store.products);

  async function getAllProducts() {
    const result = await axiosGetAllProducts();
    if (result[0]) {
      dispatch({
        type: reducerTypes.GET_PRODUCTS,
        payload: result,
      });
    }
  }

  async function getAllDetails() {
    const result = await axiosGetAllDetails();
    if (result[0]) {
      dispatch({
        type: reducerTypes.GET_DETAILS,
        payload: result,
      });
    }
  }

  useEffect(() => {
    if (!products[0]) {
      getAllProducts();
    }
    if (!details[0]) {
      getAllDetails();
    }
    // eslint-disable-next-line
  }, [products, details]);

  return (
    <Box maxWidth="100vw">
      <Stack sx={{ width: "100%" }}>
        <Container maxWidth="lg1286" style={{ textAlign: "center" }}>
          <HomePageContainer>
            <Typography variant="h2">Продукты нахуй</Typography>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {products?.map((item: any) => (
                <Product item={item} key={item.id} />
              ))}
            </div>
            <Button style={{ width: "203px", height: "48px" }} variant="outlined" onClick={() => setOpen((prev) => !prev)}>
              {" "}
              <Typography variant={"body2"} sx={{ letterSpacing: "2px", fontSize: "16px" }}>
                Опен нахуй
              </Typography>
            </Button>
            {open ? <AdminWindow /> : null}
          </HomePageContainer>
        </Container>
      </Stack>
    </Box>
  );
}

export default HomePage;
