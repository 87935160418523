import axios from "./axios.ts";

let controllerGetAllDetails: AbortController | null = null;

export const axiosGetAllDetails = async () => {
  try {
    if (controllerGetAllDetails !== null) {
      controllerGetAllDetails.abort();
    }
    controllerGetAllDetails = new AbortController();
    const { data } = await axios.get("api/details/getAll", { signal: controllerGetAllDetails.signal });
    return data.details;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log("Request canceled", e.message);
    } else {
      console.error(e);
    }
  }
};

export const axiosCreateDetail = async ({ name, count, product_id }) => {
  try {
    const { data } = await axios.post("api/details/create", { name, count, product_id });
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const axiosUpdateDetail = async ({ id, name, count }) => {
  try {
    const { data } = await axios.post("api/details/update", { id, name, count });
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const axiosDeleteDetail = async ({ id }) => {
  try {
    const { data } = await axios.post("api/details/delete", { id });
    return data;
  } catch (e) {
    console.error(e);
  }
};
