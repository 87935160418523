import { combineReducers } from "redux";
import { ProductsReducer } from "./Products/reducer.ts";
import { IProductsReducer } from "./Products/reducer.ts";

export interface IRootReducer {
  products: IProductsReducer;
}

export const rootReducer = combineReducers<IRootReducer>({
  products: ProductsReducer,
});
